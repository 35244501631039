//////画像切り替え
$(function () {
  var width = $(window).width();
  if( width < 667 ){
    $("img").each(function(){
      $(this).attr("src", $(this).attr("src").replace("_pc","_sp"));
    })
  }
});
//////ナビゲーション
$(function(){
  $('.menu_trigger').on('click',function(){
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
    $('.header_contents').addClass('active');
  });
});
$('.header_nav_contents a').on('click', function(){
  if (window.innerWidth <= 1150) {
    $('.menu_trigger').click();
  }
});	
$(window).on('resize', function() {
	if( 'none' == $('.header_nav_contents').css('display') ){
		$('.header_nav_contents').attr('style','');
	}
});
//////ナビゲーション(sub pc)
jQuery(document).ready(function ($) {
  if (window.matchMedia('(min-width: 1150px)').matches) {
    $(".h_nav_li").hover(function () {
      $(".h_nav_area").toggleClass("active_s");
      $(this).children(".h_nav_area").stop().slideToggle();
    });
  } else {
    $(".h_nav_li").click(function () {
      $(".h_nav_area").toggleClass("active_s");
      $(this).children(".h_nav_area").slideToggle();
      $(".h_nav_li").not($(this)).children(".h_nav_area").slideUp();
    });
  }
});
//////ヘッダー変更
$(window).scroll(function(){
  if ($(window).scrollTop() > 50) {
    $('.header').addClass('header_fixed');
  } else {
    $('.header').removeClass('header_fixed');
  }
});

//////ページトップ
$(function () {
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
});
//////スムーススクロール
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 1500 ;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});
//////スムーススクロールIE
if(navigator.userAgent.match(/(msie|MSIE) 10/i) || navigator.userAgent.match(/(T|t)rident\/7\./) || navigator.userAgent.match(/Edge\/\d+\.\d+/)) {
   $('body').on("mousewheel", function () {
     if(event.preventDefault){
        event.preventDefault();
     }else{
        event.returnValue=false;
     }
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}
//////telリンク
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
  $('a[href^="tel:"]').on('click', function(e) {
    e.preventDefault();
  });
}
//////サイドバナー
$(function() {
  var btn = $('.side_btn');
  $(window).on('load scroll', function(){
    if($(this).scrollTop() > 100) {
      btn.addClass('fixed');
    }else{
      btn.removeClass('fixed');
    }
  });
  //フッターの手前
  $(window).on('load scroll', function(){
    var height = $(document).height(),
        position = window.innerHeight + $(window).scrollTop(),
        footer = $('.footer').height();
    if ( height - position  < footer ){ 
      btn.addClass('absolute');
    } else { 
      btn.removeClass('absolute');
    }
  });
});
//////スライダー
$(function () {
  var mySwiper = new Swiper('.swiper-container', {
    autoplay: {
    delay: 3000,
    },
    loop: true,
    speed: 4500,
 });
})
//////new一覧（wp)
$(function () {
  $('.post_nav li a').each(function () {
    var $href = $(this).attr('href');

    if (location.href.match($href)) {
      $(this).parent().addClass('active');
    } else {
      $(this).parent().removeClass('active');
    }
  });
});
